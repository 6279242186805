<template>
  <div class="pagination">
    <div class="col-sm-6">
      <div class="float-sm-end">
        <ul class="pagination mb-sm-0">
          <li
            class="page-item"
            :class="{disabled: source?.current_page === 1}"
          >
            <a
              href="#"
              class="page-link"
              @click="navigate($event, source?.current_page-1)"
            >
              <i class="mdi mdi-chevron-left"></i
            ></a>
          </li>
          <li
            class="page-item"
            :class="{active: source?.current_page === page}"
            v-for="page in pages"
            :key="page"
            @click="navigate($event, page)"
          >
            <a class="page-link">{{ page }}</a>
          </li>
          <li
            class="page-item"
            :class="{disabled: source?.current_page === source?.last_page}"
          >
            <a
              href="#"
              class="page-link"
              @click="navigate($event, source?.current_page+1)"
            >
              <i class="mdi mdi-chevron-right"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, toRefs } from "vue";

export default {
  name: 'Pagination',
  props: {
    source: {
      type: Object,
    }
  },
  setup(props, ctx) {
    let self = ref(this)
    let pages = ref([]);
    const { source } = toRefs(props)

    function navigate(ev, page){
      ev.preventDefault()
      ctx.emit('navigate', page)
    }

    watch(source, (newValue, oldValue) => {
      if (newValue?.last_page !== oldValue?.last_page){
        pages.value = []
        for (let i = 1; i < newValue?.last_page + 1; i++) {
          pages.value.push(i);
        }
      }
    }, { deep: true });

    return {
      pages,
      navigate,
      self,
    }
  }
};
</script>