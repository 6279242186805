<script>
import Layout from "../../layouts/main";
import { ref, reactive } from "vue";
import { onMounted } from "vue";
import pagination from "../utility/Pagination"
import { listRaffleOrders } from "./api";
import { useRoute } from "vue-router";
import { formatDatetimeBR } from "../../../utils/date";
import { moneyFormat } from "../../../utils/formatter";
import RaffleNumbersListModal from "./RaffleNumbersListModal";
import RaffleApproveOrderModal from "./RaffleApproveOrderModal"
import RaffleCancelOrderModal from "./RaffleCancelOrderModal";
import { Toasts } from "../../../utils/toast-alerts";

export default {
  setup() {
    let loading = ref(false);
    let raffleOrdersList = ref({});
    const route = useRoute();
    const { id } = reactive(route.params);
    let pagination = ref(null);
    let removing = ref(null)
    let orderData = ref({})
    const filters = reactive({
      search: "",
      page: 1,
    });

    async function fetchRaffleOrders(params) {
      loading.value = true;
      try {
        const response = await listRaffleOrders(id,{
          page: params
        } );
        raffleOrdersList.value = response.data;
        pagination.value = response;

      }catch (error) {
        Toasts('error', 'Falha ao carregar as contas bancárias')
      } finally {
        loading.value = false;
      }
    }

    function onSubmit(){
      fetchRaffleOrders()
    }

    function raffleOrders(order){
      orderData.value = order
    }

    function navigate(page) {
      fetchRaffleOrders(page)
    }

    onMounted(() => {
      fetchRaffleOrders(filters);
    });

    return {
      loading,
      raffleOrdersList,
      onMounted,
      pagination,
      navigate,
      fetchRaffleOrders,
      filters,
      removing,
      confirm,
      formatDatetimeBR,
      moneyFormat,
      raffleOrders,
      orderData,
      onSubmit
    };
  },
  components: {
    Layout,
    pagination,
    RaffleNumbersListModal,
    RaffleApproveOrderModal,
    RaffleCancelOrderModal
  },
};

</script>

<template>
  <Layout :pagetitle="'Pedidos'">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="mb-3">
          <h5 class="card-title">
            Pedidos <span class="text-muted fw-normal ms-2"></span>
          </h5>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="table-responsive">
            <table
                class="table project-list-table table-nowrap align-middle table-borderless"
            >
              <thead>
              <tr>
                <th scope="col">Nº do pedido</th>
                <th scope="col">R$ Total</th>
                <th scope="col">Cliente</th>
                <th scope="col">WhatsApp</th>
                <th scope="col">Status</th>
                <th scope="col">Data do pedido</th>
                <th scope="col">Ações</th>
              </tr>
              </thead>

              <tbody v-for="orders in raffleOrdersList" :key="orders.id">
              <tr >
                <td>
                  <a class="text-body">{{ orders.id }}</a>
                </td>
                <td>{{ moneyFormat(orders.total) }}</td>
                <td>{{ orders.user.name }}</td>
                <td>{{ orders.user.whatsapp }}</td>
                <td>
                    <span
                        class="badge badge-soft-success mb-0"
                        v-if="['PROCESSING', 'CONFIRMED', 'CANCELED'].includes(orders.status)"
                        :style="(orders.status === 'PROCESSING') ? 'color:blue' : orders.status === 'CONFIRMED' ?  'color:green' : 'color:red'"
                    >
                    {{ orders?.status === 'PROCESSING' ? 'Processando' : orders?.status === 'CONFIRMED' ? 'Confirmado' : 'Cancelado' }}
                    </span>
                  <span
                      class="badge badge-soft-success mb-0"
                      v-else
                      style="color: red"
                  >Pendente
                    </span>
                </td>
                <td>{{ formatDatetimeBR(orders.created_at) }}</td>
                <td>
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                          v-if="['PENDING', 'CONFIRMED'].includes(orders.status)"
                          data-bs-toggle="modal"
                          data-bs-target=".numbers-raffle-list"
                          @click="raffleOrders(orders)"
                          data-bs-placement="top"
                          title="Visualizar números"
                          class="px-2 text-primary"
                      ><i class="bx bx-grid font-size-18"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a
                          v-if="orders.status === 'PENDING'"
                          data-bs-toggle="modal"
                          data-bs-target=".approve-order-modal"
                          data-bs-placement="top"
                          title="Aprovar pedido"
                          @click="raffleOrders(orders)"
                          class="px-2 text-success"
                          :disabled="orders.status === 'PROCESSING'"
                      ><i class="bx bx-list-check font-size-18"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a
                          v-if="orders.status === 'PENDING'"
                          data-bs-toggle="modal"
                          data-bs-target=".cancel-order-modal"
                          data-bs-placement="top"
                          title="Cancelar pedido"
                          @click="raffleOrders(orders)"
                          class="px-2 text-danger"
                          :disabled="orders.status === 'PROCESSING'"
                      ><i class="bx bx-x-circle font-size-18"></i
                      ></a>
                    </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
            <pagination
              :source="pagination"
              v-on:navigate="navigate"
            ></pagination>
            <raffle-numbers-list-modal
              :order="orderData"
            >
            </raffle-numbers-list-modal>
            <raffle-approve-order-modal
              :order="orderData"
              @submit="onSubmit()"
            >
            </raffle-approve-order-modal>
            <raffle-cancel-order-modal
              :order="orderData"
              @submit="onSubmit()"
            >
            </raffle-cancel-order-modal>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>