<script>
import { ref, toRefs } from "vue";
import { getFindNumber } from "./api";
import { Toasts } from "../../../utils/toast-alerts";

export default {
  name: 'findNumbersModal',
  props:{
    order:{
      type: Object
    },
  },
  setup(props)  {
    const { order } = toRefs(props)
    let numberRaffle = ref(null)
    let numberData = ref({})
    let notExistNumber = ref(false)

    async function submitFindNumber(){
      try {
        numberData.value = await getFindNumber({
          number: numberRaffle.value,
          contest_id: order.value.id
        })
        notExistNumber.value = true

      }catch (error) {
        Toasts('error', 'Falha ao carregar número')
      }
    }

    return {
      numberRaffle,
      submitFindNumber,
      numberData,
      notExistNumber
    };
  },
  components: {

  },
};
</script>
<template>
  <div
      class="modal fade numbers-raffle-list"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
  >
    <div
        class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >
            Números do pedido
          </h5>
          <button
              type="button"
              class="btn-close"
              id="form-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="cancelSubmit"
          ></button>
        </div>
        <div class="row mt-2">
          <div>
            <span
                v-for="number in order.numbers"
                :key="number.id"
                class="badge bg-success font-size-14 me-1 my-1 mx-1">{{ number['number'] }}</span>
          </div>
          <div class="row mt-2">
            <div class="col-12 text-end">
              <button
                  type="button"
                  class="btn btn-danger me-1 "
                  data-bs-dismiss="modal"
                  style="margin-bottom: 20px"
              ><i class="bx bx-x me-1"></i> Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
