import { axios } from '../../../axios'

export const getRaffles = (params = {}) => {
  return axios.get('/contests/manage', { params }).then(({ data }) => data)
}

export const getRaffle = (id) => {
  return axios.get(`/seller/contests/${id}`).then(({ data }) => data)
}

export const removeRaffle = (id = {}) => {
  return axios.delete(`/seller/contests/${id}`).then(({ data }) => data)
}

export const createRaffle = (params = {}) => {
  return axios.post('seller/contests',  params ).then(({ data }) => data)
}

export const updateRaffle = (id , params = {}) => {
  return axios.put(`/seller/contests/${id}`,  params ).then(({ data }) => data)
}

export const getFindNumber = (params = {})=> {
  return axios.get(`numbers/find-number`, { params }).then(({ data }) => data)
}

export const listRaffleOrders = (id, params = {}) => {
  return axios.get(`contests/manage/${id}/orders`, { params }).then(({ data }) => data)
}

export const adminPaidOrder = (contestId, orderId) => {
  return axios.put(`numbers/manage/${contestId}/orders/${orderId}/paid`).then(({ data }) => data)
}

export const adminCancelOrder = (contestId, orderId) => {
  return axios.delete(`numbers/manage/${contestId}/orders/${orderId}/cancel`).then(({ data }) => data)
}

export const getRaffleNumbersStatus = (raffleId, params = {}) => {
  return axios.get(`numbers/${raffleId}/status`, { params }).then(({ data }) => data)
}

export const finishRaffle = (contestId, number) => {
  return axios.put(`contests/manage/${contestId}/finish/${number}`).then(({ data }) => data)
}

export const fetchUserProfile = () => {
  return axios.get('auth/profile').then(({ data }) => data)
}

export const fetchUsersUnacceptedTerms = () => {
  return axios.get('/seller/terms/get-pending-terms').then(({ data }) => data)
}

export const showTerm = (id) => {
  return axios.get(`/seller/terms/${id}`).then(({ data }) => data)
}

export const acceptTermOfUse = (params = {}) => {
  return axios.post('/seller/terms/accept', params).then(({ data }) => data)
}

export const fetchContestQuotas = (params = {}) => {
  return axios.get('/seller/contest-quotas', params).then(({ data }) => data)
}

export const storeSeveralContestQuotas = (params = {}) => {
  return axios.post('/seller/contest-quotas/several', params).then(({ data }) => data)
}

export const syncContestStats = (id) => {
  return axios.get(`/seller/contests/${id}/sync-stats`).then(({ data }) => data)
}
