<script>
import { ref, toRefs } from "vue";
import { adminPaidOrder } from "./api";
import { Toasts } from "../../../utils/toast-alerts";

export default {
  name: 'findNumbersModal',
  props:{
    order:{
      type: Object
    },
  },
  setup(props, { emit })  {
    const { order } = toRefs(props)
    let numberRaffle = ref(null)
    let numberData = ref({})
    let notExistNumber = ref(false)

    async function approveOrder(){
      try {

        await adminPaidOrder(order.value.contest_id, order.value.id);

        Toasts('success', 'Pedido aprovado')
        const btnClose = document.querySelector("#modal-btn-close")
        btnClose.click()

        emit('submit')
      } catch (e) {
        Toasts('error', 'Falha ao aprovar o pedido')
      }
    }

    return {
      numberRaffle,
      numberData,
      notExistNumber,
      approveOrder
    };
  },
  components: {

  },
};
</script>
<template>
  <div
      class="modal fade approve-order-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
  >
    <div
        class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >
            Aprovar pedido
          </h5>
          <button
              type="button"
              class="btn-close"
              id="modal-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="cancelSubmit"
          ></button>
        </div>
        <div class="row mt-2">
          <div class="modal-body">
            <a>
              Após clicar em "Aprovar pagamento", o pedido será aprovado e o cliente
              será notificado por WhatsApp. <br />
              Portanto, é necessário verificar o comprovante enviado pelo cliente e se
              certificar de que o pagamento foi debitado em sua conta. Está ação
              <strong>NÃO</strong> pode ser revertida.
            </a>
          </div>
          <div class="modal-header">
            <a>
              <strong>Deseja aprovar o pagamento do pedido?</strong>
            </a>
          </div>
          <div class="row mt-2">
            <div class="col-12 text-end">
              <button
                  type="button"
                  class="btn btn-danger me-1 "
                  data-bs-dismiss="modal"
                  style="margin-bottom: 20px"
              ><i class="bx bx-x me-1"></i> Fechar
              </button>
              <button
                  class="btn btn-success"
                  data-bs-target="#success-btn"
                  id="btn-save-event"
                  type="button"
                  @click="approveOrder"
                  style="margin-bottom: 20px"
              >
                <i class="bx bx-check me-1"></i> Aprovar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
